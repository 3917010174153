.App {
  text-align: center;
}


.smallBox{
  position:relative;
  width: 44px;
  height: 44px;
}


.App-logo {
  pointer-events: none;
  height: 192px;
  width: 192px;
  user-select: none; 
}


.CI-Border {
  background-color: #57A359; 
  height: 44px;
}


.App-header {
  background-color: white;
  height: 600px;
  flex-direction: column;
  align-items: left;
  justify-content: left;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Spinner {
  position:relative;
  top:0;
  left:0;
  right:0;
  bottom:0;
  z-index: 9999;
  background-color: beige;
  height: 100%;
}

#grid {
  display: grid;
  color: black;
  font-size: calc(2vmin);
  font-weight: bold;
  align-items: center;
  grid-template-columns: 20% 50% 30%;
}

#gridSpinner {
  display: grid;
  color: black;
  font-size: calc(2vmin);
  font-weight: bold;
  align-items: center;
  text-align: center;
  grid-template-columns: 25% 50% 25%;
}

#mylog {
  color: black;
  font-size: 16px;
  text-align: left;
}


.Default {
  font-size: 20px;
  white-space: nowrap;
  padding: 10px;
}

.Select-CFG {
  white-space: nowrap;
  vertical-align: middle;
  background-color: #57A359;
  border-radius: 22px;
}